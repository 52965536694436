import { NewspaperIcon, SwitchHorizontalIcon } from '@heroicons/react/outline'
import LoadingContent from 'components/LoadingContent'
import SwitchIcon from 'components/Icons/SwitchIcon'
import { useBlogWithSlug, useCurrentBlogId } from 'data/blogs/client'
// import { first } from 'lodash'
import { useSession } from 'next-auth/react'
import styles from './AccountHeading.module.css'
import TwitterIcon from 'components/Icons/Twitter'
import { Session } from '@prisma/client'
import { useUser } from 'data/users/client'

type SessionWithProfile = Session & {
  profile: {
    screen_name: string,
  }
}

const AccountHeading = () => {
  const { user } = useUser()
  const { data: sessionData } = useSession()
  const session = sessionData as SessionWithProfile | null
  const blogSlug = useCurrentBlogId()
  const { blog, isBlogLoading } = useBlogWithSlug(blogSlug)
  // const blog = first(blogs)
  if (isBlogLoading) {
    return <LoadingContent />
  }
  return <div className={styles.accountHeading}>
    {/* Profile */}
    <div className={styles.accountHeadingInner}>
      <div className={styles.titleContainerPlusGoodies}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            <div className={styles.titleText}>
              <div className={styles.domainName}>{ blog?.name || 'Your Blog' }</div>
              <div className={styles.switchIconDesktop}>
                <SwitchHorizontalIcon width={24} />
              </div>
              <div className={styles.twitterHandle}>
                @{ user?.screen_name || session?.profile?.screen_name }
              </div>
            </div>
            <div className={styles.switchIconMobile}>
              <SwitchIcon height={40} width={40} />
            </div>
          </h1>
        </div>
        <dl className="mt-6 hidden sm:flex sm:mt-1 sm:justify-between">
          <dt className="sr-only">Blog name and domain</dt>
          <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
            <NewspaperIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <div className='max-w-xs overflow-ellipsis overflow-hidden whitespace-nowrap block'>{blog?.url?.replace('https://', '')}</div>
          </dd>
          <dt className="sr-only">Twitter label</dt>
          <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mt-0 flex-row-reverse">
            <TwitterIcon
              className="flex-shrink-0 ml-1.5 h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
            on Twitter
          </dd>
        </dl>
      </div>
    </div>
  </div>
}

export default AccountHeading
