import styles from './DashboardView.module.css'
import BlogPostMetrics from 'components/BlogPostMetrics'
import OnboardingChecklist from 'components/OnboardingChecklist'
import InstallationSnippetWidget from 'components/InstallationSnippetWidget'
import ListOfContent from 'components/ListOfContent'
// import LoadingContent from 'components/LoadingContent'
// import AtomSpinner from 'components/Spinner/AtomSpinner'
import { usePagesForDomain } from 'data/pages/client'
import { getContentItemFromPage, getContentItemRepliesAcrossPages } from 'data/pages/mapping'
import { useBlogWithSlug } from 'data/blogs/client'
import PageApp from 'components/PageApp'
import AccountHeading from 'components/AccountHeading'

type Props = {
  blogSlug: string,
}

// const getActions = (blogSlug: string) => [{
//   label: 'You have unshared blog posts',
//   description: 'Sharing your blog posts helps build your audience and spread your work. Blotter can help you share your blog posts.',
//   url: `/app/host/${blogSlug}/blogposts?filter=unshared`,
// }, {
//   label: 'Another one',
//   description: 'Oh, we’ve got ideas',
//   url: null,
// }]

const isMetricsReadyExternal = false

const DashboardView = ({ blogSlug }: Props) => {
  const { pages, isPagesLoading } = usePagesForDomain()
  const { blog, isBlogLoading } = useBlogWithSlug(blogSlug)
  console.log({ blog, pages })
  const isMetricsReady = isMetricsReadyExternal
  const isPageViewsDetected = !!pages?.find(page => page.isPageViewConfirmed)
  const isLoading = isPagesLoading || isBlogLoading
  // const actions = getActions(blogSlug)

  return <PageApp
    top={
      !isLoading && <div className={styles.mobileOnboardingChecklist}>
        <OnboardingChecklist name='mobile' />
      </div>
    }
    heading={<AccountHeading />}
    isLoading={isLoading}
    loadingLabel='Loading Dashboard...'
  >
    {
      <>
        <div className="mt-0">
          <div className={`transition-all duration-800 ${isPageViewsDetected ? 'm-0 max-h-0 overflow-hidden opacity-0' : 'm-4 mb-16 sm:max-h-screen opacity-100'}`}>
            <InstallationSnippetWidget />
          </div>
          <>
            { isMetricsReady && <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <BlogPostMetrics />
            </div> }
            {/* { actions && <div>You've got suggestions</div> } */}
            { pages && <ListOfContent type='blogpost' content={pages.map(getContentItemFromPage)} metaData={{ blog }} maxItems={5} /> }
            { pages && <ListOfContent type='comment' content={getContentItemRepliesAcrossPages(pages)} maxItems={5} /> }
            {/* { isSharesDetected && <ListOfContent type='tweet' /> } */}
          </>

          {/* { isPagesDetected && isMetricsProcessing &&
            <LoadingContent
              label={<span>Processing Tweets<br />and Blogposts</span>}
              spinner={AtomSpinner}
            />
          } */}
        </div>
      </>
    }
  </PageApp>
}

export default DashboardView
