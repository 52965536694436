import DashboardView from 'views/DashboardView'
import PageWithTailwind from 'components/PageWithTailwind'
import { useCurrentBlogId } from 'data/blogs/client'

const DashboardPage = () => {
  const blogSlug = useCurrentBlogId()
  return <PageWithTailwind title='Dashboard'>
    <DashboardView blogSlug={blogSlug as string} />
  </PageWithTailwind>
}

// export async function getServerSideProps() { // context: GetServerSidePropsContext
//   const providers = await getProviders()
//   return {
//     props: { providers },
//   }
// }

export default DashboardPage
